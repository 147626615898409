import { Component, OnInit,AfterViewChecked, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AngularFireDatabase , AngularFireDatabaseModule} from '@angular/fire/database';
import * as firebase from 'firebase';
import { ContactSubmission } from '../models/contact-submission';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import 'rxjs/add/operator/switchMap';

@Component({
  selector: 'pb-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements AfterViewChecked {
  model = new ContactSubmission();
  contactRecipientPop = null;
  contactChannelPop = null;
  submitted = false;
  charsLeft = 250;
  contactForm: NgForm;
  @ViewChild('contactForm', { static: true }) currentForm: NgForm;
  submittedForm: any; // we will get rid of this later
  formErrors = {
    'contactRecipient': '',
    'contactName': '',
    'contactEmail': '',
    'contactMessage': ''
  };
  validationMessages = {
    'contactRecipient': {
      'required': 'Recipient is required.'
    },
    'contactName': {
      'required': 'Name is required.'
    },
    'contactEmail': {
      'required': 'Email is required.',
      'validEmail': 'Email must be in a valid format.'
    },
    'contactMessage': {
      'required': 'A message is required.'
    },
    'captcha': {
      'required': 'Are you a robot?'
    }
  };
  constructor(public db: AngularFireDatabase,
    private route: ActivatedRoute) {

  }
  ngOnInit() {
    this.contactRecipientPop = this.route.snapshot.paramMap.get('id');
    console.log(this.contactRecipientPop);
    this.contactChannelPop = this.route.snapshot.paramMap.get('channel');
    console.log(this.contactChannelPop);
    this.model.contactRecipient = this.contactRecipientPop;
    this.model.contactRecipientChannel = this.contactChannelPop;
  }
  ngAfterViewChecked() {
    this.formChanged();
  }
  formChanged() {
    if (this.currentForm === this.contactForm) { return; }
    this.contactForm = this.currentForm;
    if (this.contactForm) {
      this.contactForm.valueChanges
        .subscribe((data) => this.onValueChanged(data));
    }
  }
  onValueChanged(data?: any) {
    if (!this.contactForm) { return; }
    const form = this.contactForm.form;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  onSubmitCheck() {
    if (!this.contactForm) { return; }
    const form = this.contactForm.form;
    for (const field in this.formErrors) {
      // clear previous error message (if any)
      this.formErrors[field] = '';
      const control = form.get(field);
      if (!control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
  sendAnother() {
    this.contactForm.reset()
    this.submittedForm = "";
    this.submitted = false;
    this.model = new ContactSubmission();
    this.charsLeft = 250;
  }
  onSubmit() {
    if (!this.contactForm.form.invalid) {
      const messages = this.db.list('/untested-messages/');
      this.model.contactRecipient = this.model.contactRecipient.toLowerCase().replace(/\s/g, '');
      this.model.contactRecipientChannel = this.model.contactRecipientChannel.toLowerCase().replace(/\s/g, '');
      this.model.timestamp = firebase.database.ServerValue.TIMESTAMP;
      messages.push(this.model).then(() => {
        this.model.captcha = null;
        this.submittedForm = JSON.stringify(this.model, null, 4);
        this.submitted = true;
      }).catch((err) => {
        console.error(err);
      });
    } else {
      this.onSubmitCheck()
    }
  }
  countChars(event: any) {
    this.charsLeft = 250 - event.target.value.length;
  }
}