
export class ContactSubmission {
    public timestamp: object;
    public contactRecipient: string;
    public contactRecipientChannel: string;
    public contactName: string;
    public contactEmail: string;
    public contactPhoneNumber: string;
    public contactMessage: string;
    public contactWebsite: string;
    public captcha: string;
  }
  
  