import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tc',
  templateUrl: './tc.component.html',
  styleUrls: ['./tc.component.css']
})
export class TcComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
