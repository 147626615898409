import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule, Routes } from '@angular/router';


import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire//auth';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { RecaptchaModule } from 'ng-recaptcha';
import { TcpComponent } from './tcp/tcp.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TcComponent } from './tc/tc.component';



const appRoutes: Routes = [
  { path: '', component: ContactComponent },
  { path: ':id', component: ContactComponent },
  { path: ':id/:channel', component: ContactComponent },
  { path: ':id/:channel/about', component: AboutComponent },
  { path: ':id/:channel/tcp', component: TcpComponent },
  {
    path: '',
    component: ContactComponent,
    data: { title: 'Contact' }
  }
];

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    AboutComponent,
    TcpComponent,
    PrivacypolicyComponent,
    TcComponent,
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireModule,
    AngularFireAuthModule,
    SharedModule,
    RecaptchaModule.forRoot(),
    NgbModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
