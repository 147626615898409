// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // ***********************************************************************************************************************
    // * TODO(DEVELOPER): Update values according to: Firebase Console > Overview > Add Firebase to your web app. *
    // ***********************************************************************************************************************
    apiKey: "AIzaSyDYcEETt2kfzmqhY1uAWCjEgszIwVbOmMg",
    authDomain: "open-contact.firebaseapp.com",
    databaseURL: "https://open-contact.firebaseio.com",
    projectId: "open-contact",
    storageBucket: "open-contact.appspot.com",
    messagingSenderId: "876400941742"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
